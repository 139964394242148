import { gql } from '@apollo/client';
const GET_ASSEMBLY_PROJECTS = gql`
  query getAssemblyProjects {
    getAssemblyProjects {
      id
      name
      creation_date
      is_owner
      thumb
      last_modification_date
      durationInSeconds
      shares {
        id
        invitee
      }
      owner {
        full_name
        email
        avatar
      }
      imported_media {
        id
      }
      archived
    }
  }
`;
const GET_ASSEMBLY_PROJECT = gql`
  query getAssemblyProject($projectId: ID!) {
    getAssemblyProject(projectId: $projectId) {
      id
      name
      is_owner
      height
      width
      timecode
      fps
      imported_media {
        id
        name
        url
        startTimeCode
        fps
        type
        height
        width
        durationInSeconds
        type
        archived
        project_folder_id
      }
      permission
      my_privilege
      creation_date
      shares {
        id
        invitee
        privilege
      }
      folders {
        id
        name
        archived
        imported_media {
          id
          name
          archived
          project_folder_id
        }
      }
    }
  }
`;
const GET_ASSEMBLY_PROJECT_AND_ALL_MEDIA = gql`
  query getAssemblyProject($projectId: ID!) {
    getAssemblyProject(projectId: $projectId) {
      id
      name
      is_owner
      imported_media {
        id
        name
        url
        startTimeCode
        fps
        type
        height
        width
        durationInSeconds
        localClientPathFile
        bookmarksProcessed
        archived
        transcript {
          words {
            text
            startTime
            endTime
            style
          }
          speaker {
            name
            color
          }
          annotation
        }
      }
    }
  }
`;
const GET_ASSEMBLY_MEDIA = gql`
  query getAssemblyMedia($mediaId: ID!) {
    getAssemblyMedia(mediaId: $mediaId) {
      id
      name
      url
      type
      startTimeCode
      localClientPathFile
      bookmarksProcessed
      archived
      folder {
        id
        name
        archived
      }
      transcript {
        words {
          text
          startTime
          endTime
          style
        }
        speaker {
          name
          color
        }
        annotation
      }
      language_region_code
    }
  }
`;
const GET_ASSEMBLY_LATEST_VERSION = gql`
  query getLatestAssembly($projectId: ID!) {
    getLatestAssembly(projectId: $projectId) {
      id
      version
      segments {
        id
        fingerprint
        start
        end
        text
        imported_media {
          id
          name
          url
          type
          startTimeCode
        }
        selection {
          anchorKey
          anchorOffset
          focusKey
          focusOffset
          isBackward
          hasFocus
          anchorIndex
          focusIndex
        }
        sort_index
        is_chapter
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
        reactions {
          type
          is_editable
        }
      }
    }
  }
`;
const ASSEMBLY_UPDATED_SUBSCRIPTION = gql`
  subscription assemblyUpdated($projectId: ID!) {
    assemblyUpdated(projectId: $projectId) {
      id
      version
      segments {
        id
        fingerprint
        start
        end
        text
        imported_media {
          id
          name
          url
          type
          startTimeCode
        }
        selection {
          anchorKey
          anchorOffset
          focusKey
          focusOffset
          isBackward
          hasFocus
          anchorIndex
          focusIndex
        }
        sort_index
        is_chapter
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
        reactions {
          type
          is_editable
        }
      }
    }
  }
`;

const ACTIVE_UPDATE_SUBSCRIPTION = gql`
  subscription activeUpdated($projectId: ID!) {
    activeUpdated(projectId: $projectId) {
      project_id
      visitors {
        id
        user_id
        full_name
        email
        avatar
        temp_user_id
        active_fingerprint
      }
    }
  }
`;

const SAVE_ASSEMBLY = gql`
  mutation saveAssembly($projectId: ID!, $referenceVersion: Int, $segments: [InputSegment!]!) {
    saveAssembly(projectId: $projectId, referenceVersion: $referenceVersion, segments: $segments) {
      id
      version
      segments {
        id
        fingerprint
        start
        end
        text
        imported_media {
          id
          name
          url
          type
          startTimeCode
        }
        selection {
          anchorKey
          anchorOffset
          focusKey
          focusOffset
          isBackward
          hasFocus
          anchorIndex
          focusIndex
        }
        sort_index
        is_chapter
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
        reactions {
          type
          is_editable
        }
      }
    }
  }
`;

const CREATE_ASSEMBLY_PROJECT = gql`
  mutation createNewAssemblyProject($name: String) {
    createNewAssemblyProject(name: $name) {
      id
    }
  }
`;

const CREATE_MEDIA_FOLDER_IN_PROJECT = gql`
  mutation createMediaFolderInProject($name: String!, $projectId: ID!) {
    createMediaFolderInProject(name: $name, projectId: $projectId) {
      id
      folders {
        id
        name
      }
    }
  }
`;

const RENAME_ASSEMBLY_PROJECT = gql`
  mutation renameAssemblyProject($projectId: ID!, $name: String!) {
    renameAssemblyProject(projectId: $projectId, name: $name) {
      id
      name
    }
  }
`;

const CHANGE_PERMISSION_ASSEMBLY_PROJECT = gql`
  mutation changeAssemblyProjectPermission($projectId: ID!, $permission: Permission!) {
    changeAssemblyProjectPermission(projectId: $projectId, permission: $permission) {
      id
      shares {
        id
        invitee
        privilege
      }
      permission
    }
  }
`;

const ADD_PERMISSION_FOR_EMAILS = gql`
  mutation addPermissionForEmails($projectId: ID!, $emails: [String!]!, $privilege: Permission!) {
    addPermissionForEmails(projectId: $projectId, emails: $emails, privilege: $privilege) {
      id
      shares {
        id
        invitee
        privilege
      }
      permission
    }
  }
`;

const AMEND_PERMISSION_FOR_EMAIL = gql`
  mutation amendPermissionForEmail($projectId: ID!, $email: String!, $privilege: Permission!) {
    amendPermissionForEmail(projectId: $projectId, email: $email, privilege: $privilege) {
      id
      shares {
        id
        invitee
        privilege
      }
      permission
    }
  }
`;

const AMEND_MEDIA = gql`
  mutation amendMedia($mediaId: ID!, $folderId: ID) {
    amendMedia(mediaId: $mediaId, folderId: $folderId) {
      id
      folders {
        id
        name
      }
    }
  }
`;

const AMEND_MEDIA_FOLDER = gql`
  mutation amendMediaFolder($folderId: ID!, $name: String!) {
    amendMediaFolder(folderId: $folderId, name: $name) {
      id
    }
  }
`;

const ADD_ANNOTATION_TO_SEGMENT = gql`
  mutation createAnnotation($segmentFingerprint: ID!, $text: String!) {
    createAnnotation(segmentFingerprint: $segmentFingerprint, text: $text) {
      id
      version
      segments {
        id
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
      }
    }
  }
`;

const AMEND_ASSEMBLY_PROJECT = gql`
  mutation amendAssemblyProject($projectId: ID!, $width: Int, $height: Int, $fps: String, $timecode: String) {
    amendAssemblyProject(projectId: $projectId, width: $width, height: $height, fps: $fps, timecode: $timecode) {
      id
    }
  }
`;

const AMEND_ANNOTATION_TO_SEGMENT = gql`
  mutation amendAnnotation($annotationId: ID!, $text: String!) {
    amendAnnotation(annotationId: $annotationId, text: $text) {
      id
      version
      segments {
        id
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
      }
    }
  }
`;

const AMEND_REACTION_TO_SEGMENT = gql`
  mutation amendReaction($segmentFingerprint: ID!, $type: ReactionType!, $remove: Boolean) {
    amendReaction(segmentFingerprint: $segmentFingerprint, type: $type, remove: $remove) {
      id
      version
      segments {
        id
        reactions {
          type
          is_editable
        }
      }
    }
  }
`;

const SET_BOOKMARKS_PROCESSED = gql`
  mutation markMediaBookmarksAsProcessed($mediaId: ID!) {
    markMediaBookmarksAsProcessed(mediaId: $mediaId) {
      id
      name
      url
      startTimeCode
      localClientPathFile
      bookmarksProcessed
      transcript {
        words {
          text
          startTime
          endTime
          style
        }
        speaker {
          name
          color
        }
        annotation
      }
    }
  }
`;

const GET_EXPORT = gql`
  query getExport($projectId: ID!, $fileType: String!, $extension: String!) {
    getExport(projectId: $projectId, fileType: $fileType, extension: $extension) {
      base64Payload
      url
      fileType
      extension
    }
  }
`;

const ARCHIVE_ASSEMBLY_PROJECT = gql`
  mutation archiveAssemblyProject($projectId: ID!, $archive: Boolean!) {
    archiveAssemblyProject(projectId: $projectId, archive: $archive) {
      id
      name
      archived
    }
  }
`;

const ARCHIVE_MEDIA_ASSEMBLY_PROJECT = gql`
  mutation archiveMediaInAssemblyProject($projectId: ID!, $mediaId: ID!) {
    archiveMediaInAssemblyProject(projectId: $projectId, mediaId: $mediaId) {
      id
    }
  }
`;

const ARCHIVE_MEDIA_FOLDER = gql`
  mutation archiveMediaFolder($folderId: ID!, $archive: Boolean) {
    archiveMediaFolder(folderId: $folderId, archive: $archive) {
      id
    }
  }
`;

const GET_ACTIVE_VISITORS = gql`
  query getActiveVisitors($projectId: ID!, $session: ID!) {
    getActiveVisitors(projectId: $projectId, session: $session) {
      id
      user_id
      full_name
      email
      avatar
      temp_user_id
      active_fingerprint
    }
  }
`;

const MARK_AS_ACTIVE_VISITOR = gql`
  mutation markAsActiveVisitor($projectId: ID!, $session: ID!, $active: Boolean, $activeFingerprint: ID) {
    markAsActiveVisitor(
      projectId: $projectId
      session: $session
      active: $active
      active_fingerprint: $activeFingerprint
    ) {
      id
      user_id
      full_name
      email
      avatar
      temp_user_id
      active_fingerprint
    }
  }
`;

const CLONE_ASSEMBLY_PROJECT = gql`
  mutation cloneAssemblyProject($projectId: ID!) {
    cloneAssemblyProject(projectId: $projectId) {
      id
    }
  }
`;

const GET_LIST_NOTI = gql`
  query getNotifications($skip: Int!, $take: Int!) {
    getNotifications(skip: $skip, take: $take) {
      listNotification {
        id
        user_id
        action_type
        action_description
        creation_date
        is_read
        actor {
          full_name
          avatar
        }
        project_encoded_id
        project_name
      }
      totalUnseen
    }
  }
`;

const UPDATE_NOTI = gql`
  mutation updateReadNotification($id: Int) {
    updateReadNotification(id: $id) {
      id
    }
  }
`;

const DELETE_NOTI = gql`
  mutation deleteNotification($id: Int) {
    deleteNotification(id: $id) {
      id
    }
  }
`;

const NOTIFICATION_ADDED_SUBSCRIPTION = gql`
  subscription notificationAdded {
    notificationAdded {
      id
      user_id
      action_type
      action_description
      creation_date
      is_read
      actor {
        full_name
        avatar
      }
      project_encoded_id
      project_name
    }
  }
`;

const UPDATE_NOTI_OPENED_TIME = gql`
  mutation updateNotificationsOpenedTime {
    updateNotificationsOpenedTime
  }
`;

const SUBMIT_EMAIL_NOTI = gql`
  mutation amendEmailPreferences(
    $intercomId: ID!
    $subscribedHelp: Boolean!
    $subscribedUpdates: Boolean!
    $subscribedTransactional: Boolean!
  ) {
    amendEmailPreferences(
      intercomId: $intercomId
      subscribedHelp: $subscribedHelp
      subscribedUpdates: $subscribedUpdates
      subscribedTransactional: $subscribedTransactional
    ) {
      intercomId
      subscribedHelp
      subscribedUpdates
      subscribedTransactional
    }
  }
`;

export {
  GET_ASSEMBLY_PROJECTS,
  GET_ASSEMBLY_PROJECT,
  GET_ASSEMBLY_MEDIA,
  GET_ASSEMBLY_PROJECT_AND_ALL_MEDIA,
  GET_ASSEMBLY_LATEST_VERSION,
  ARCHIVE_ASSEMBLY_PROJECT,
  ARCHIVE_MEDIA_ASSEMBLY_PROJECT,
  ARCHIVE_MEDIA_FOLDER,
  ASSEMBLY_UPDATED_SUBSCRIPTION,
  ACTIVE_UPDATE_SUBSCRIPTION,
  SAVE_ASSEMBLY,
  CREATE_ASSEMBLY_PROJECT,
  CREATE_MEDIA_FOLDER_IN_PROJECT,
  RENAME_ASSEMBLY_PROJECT,
  CHANGE_PERMISSION_ASSEMBLY_PROJECT,
  ADD_PERMISSION_FOR_EMAILS,
  AMEND_PERMISSION_FOR_EMAIL,
  AMEND_MEDIA,
  AMEND_MEDIA_FOLDER,
  ADD_ANNOTATION_TO_SEGMENT,
  AMEND_ASSEMBLY_PROJECT,
  AMEND_REACTION_TO_SEGMENT,
  AMEND_ANNOTATION_TO_SEGMENT,
  SET_BOOKMARKS_PROCESSED,
  GET_EXPORT,
  GET_ACTIVE_VISITORS,
  MARK_AS_ACTIVE_VISITOR,
  CLONE_ASSEMBLY_PROJECT,
  GET_LIST_NOTI,
  UPDATE_NOTI,
  DELETE_NOTI,
  NOTIFICATION_ADDED_SUBSCRIPTION,
  SUBMIT_EMAIL_NOTI,
  UPDATE_NOTI_OPENED_TIME,
};
